var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dev"},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredGuilds,"search":_vm.search,"item-key":"name","loading":_vm.loading,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"disable-pagination":"","hide-default-footer":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{staticClass:"search",attrs:{"placeholder":"search..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-switch',{staticClass:"sort-switch",attrs:{"label":"Plays Music"},model:{value:(_vm.onlyPlayers),callback:function ($$v) {_vm.onlyPlayers=$$v},expression:"onlyPlayers"}})],1)]},proxy:true},{key:"item.icon",fn:function(ref){
var item = ref.item;
return [_c('guild-icon',{attrs:{"icon":item.icon,"alt":((item.name) + " profile"),"text":item.name,"size":36}})]}},{key:"item.open_in_new",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"#5c5fea","target":"_blank","to":("/guilds/" + (item.id) + "/dashboard")}},[_c('v-icon',[_vm._v("open_in_new")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.name.length > 36 ? item.name.substr(0, 35) + "..." : item.name))]}},{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.owner == undefined ? "not loaded" : item.owner))]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }