import { render, staticRenderFns } from "./Dashboard.vue?vue&type=template&id=2ca21968&scoped=true&"
import script from "./Dashboard.vue?vue&type=script&lang=js&"
export * from "./Dashboard.vue?vue&type=script&lang=js&"
import style0 from "./Dashboard.vue?vue&type=style&index=0&id=2ca21968&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ca21968",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VBtn,VDivider,VExpansionPanels,VIcon,VListItemContent,VListItemTitle,VSimpleTable,VSnackbar,VSwitch,VTextarea})
