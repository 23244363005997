<template>
  <h1>Error 404 not found!</h1>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style lang="less" scoped></style>
